
    .web-wrapper{
        position: relative;
        .head{
            
        }
        .container{
            height: 100%;
            width: 100%;
            z-index: 0;
            position: absolute;
            left: 0;
            padding: 0;
            overflow: hidden;
        }
    }