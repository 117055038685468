



.wrapper-perform {
	width: 100%;
	height: 100vh;
	background: #f9f9f9;
	overflow: hidden;
	.loading {
		margin-top: 40vh;
	}
	
	.content {
		position: relative;
		// width: 500px;
		// height: calc(100% - 100px);
		// background: #f5f5f5;
		display: flex;
		margin: 20px auto;
		flex-direction: row;
		justify-content: center;
		padding: 20px;
		height: 100vh;
		overflow: hidden;
		.videos {
			width: 476px;
			position: relative;
			 .ailab-avatar-player {
				height: calc(100vh - 0);
				overflow: hidden;
			}
			
			 .ailab-avatar-player video {
				background: transparent !important;
				width: 100% !important;
				height: auto !important;
				// margin-left: -26%!important;
				// margin-top: -65%!important;
			}
		}
		
	}

}

