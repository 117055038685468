.video .ailab-avatar-player {
	height: 40vh;
	overflow: hidden;
}

.video .ailab-avatar-player video {
	background: transparent !important;
	width: 100vw !important;
	margin-top: -29vh !important;
	height: auto !important;
}

.content {
	position: relative;
	height: 100vh;
	background: #f5f5f5;
	display: flex;
	flex-direction: column;
}

.userinfo {
	position: absolute;
	bottom: 70px;
	text-align: center;
	width: 70px;
	height: 70px;
	left: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: rgb(114, 119, 242);
	border-radius: 50%;
	margin-left: -35px;
}


.userinfo-avatar {
	width: 30px;
	height: 30px;
	min-width: 30px;
	z-index: -10;
	zoom: 1;
}

.message-list {
	width: 100%;
	/* padding-top: 40px; */
	/* max-height: 50vh;
	min-height: 50vh; */
}
.message-list ul{
	
	width: 100%;
	overflow-y: auto;
	margin: 50px 0 75px;

}
.title{
	position: fixed;
	font-size: 14px;
	border-bottom: 0.5px solid #eee;
	margin-bottom: 20px;
	background: #fff;
	width: 100%;
}
.title span{
	padding: 0 10px;
	line-height: 40px;
}

.message-item {
	width: 80%;
	margin-bottom: 20px;
}

.message-item span {
	display: inline-block;
	width: 100%;
	margin: 0 10px;
	border-radius: 7px;
	padding: 10px;
	position: relative;
	box-sizing: border-box;
}

.person1 {
	float: left;
}

.person1 span {
	background: #ffffff;
	border-radius: 0 6px 6px 6px;
}

.person2 span {
	background: #89D961;
	border-radius: 6px 0 6px 6px;
}

.person2 {
	float: right;
	margin-right: 20px;
}

ul,
ul li {
	margin: 0;
	padding: 0;
}

ul li {
	margin: 0;
	list-style-type: none;
}

.footer {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background: #ffffff;
	height: 60px;
	box-sizing: border-box;
	padding: 10px;
	display: flex;
	align-items: flex-end;
}