
    .websocket-wrapper{
        position: relative;
        .head{
            
        }
        .containers{
            height: 100%;
            width: 100%;
            z-index: 0;
            position: absolute;
            left: 0;
            padding: 0;
            overflow: hidden;
            display: flex;
            div{
                .ant-divider{
                    margin: 10px 0;
                }
                &:nth-child(1){
                    flex: 1;
                    border-right: 1px solid #eee;
                }
                &:nth-child(2){
                    flex: 1;
                    .message-list{
                        ul{
                            overflow-y: auto;
                            max-height: calc(100vh - 150px);
                        }
                    }
                }
                &.p-20px{
                    padding: 0 20px 20px;
                }
                &:nth-child(3){
                    min-width: 500px;
                    #iframe-file{
                        width: 100%;
                        height: 100%;
                        border: none;
                        
                    }
                }
            }
        }
    }