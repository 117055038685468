



.wrapper {
	width: 100%;
	height: 100vh;
	background: #f9f9f9;
	overflow: hidden;
	.loading {
		margin-top: 40vh;
	}
	.wrapper .head {
		height: 60px;
	}
	
	.head h3 {
		margin: 0;
		font-size: 20px;
		font-weight: 600;
		padding: 10px;
		box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%);
	}
	
	.content {
		position: relative;
		width: 1000px;
		height: calc(100% - 100px);
		background: #f5f5f5;
		display: flex;
		margin: 40px auto;
		flex-direction: row;
		padding: 10px;
		overflow: hidden;
	
		.position {
			position: absolute;
			top: 90px;
			left: 25%;
			margin-left: -95px;
	
			img {
				width: 190px;
			}
		}
	
		.positionBtn {
			position: absolute;
			bottom: -10px;
			left: 0;
			width: 476px;
			box-sizing: border-box;
			display: flex;
			z-index: 10;
			padding: 10px;
			background-color: rgba(255, 255, 255, .4);
			justify-content: space-around;
	
			button {
				padding: 7px 10px;
				background: rgba(255, 255, 255, .7);
				border: none;
				width: 100px;
				text-align: center;
				cursor: pointer;
			}
		}
	}
	
	.videos {
		width: 476px;
		position: relative;
		 .ailab-avatar-player {
			height: calc(100vh - 82px);
			overflow: hidden;
		}
		
		 .ailab-avatar-player video {
			background: transparent !important;
			width: 100% !important;
			height: auto !important;
			// margin-left: -26%!important;
			// margin-top: -65%!important;
		}
	}
	
	.chat-box {
		flex: 1;
		max-width: 524px;
		display: flex;
		flex-direction: column;
		border-left: 1px solid #eee;
		position: relative;
	
		.shadow {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background: #333;
			opacity: .3;
			text-align: center;
			line-height: 1300px;
			color: #fff;
		}
	}
	
	.title-head {
		font-size: 16px;
		padding: 10px;
		background: #FAFCFE;
		border-bottom: 1px solid #eee;
		height: 42px;
		display: flex;
		box-sizing: border-box;
		justify-content: space-between;
		img{
			cursor: pointer;
		}
	
		.ant-btn-link {
			color: #02a49f;
	
			&:hover {
				color: #02a49f;
			}
		}
	
		.button {
			// padding
		}
	
		.ant-radio-checked {
			.ant-radio-inner {
				border-color: #02a49f;
				background-color: #02a49f;
			}
		}

		.ant-radio-button-wrapper-checked{
			border-color: #02a49f;
			color: #02a49f;
		}
	
		.ant-radio-group {
			display: flex;
			align-items: center;
		}
	}
	
	
	.list-content {
		width: 100%;
		overflow-y: auto;
		padding-top: 20px;
		display: flex;
		flex-direction: column;
		min-height: calc(100% - 110px);
		max-height: calc(100% - 110px);
		background-color: #fff;
	}
	
	.list-content::-webkit-scrollbar-track-piece {
		background: #f1f1f1;
	}
	
	.list-content::-webkit-scrollbar {
		width: 0;
	}
	
	.list-content::-webkit-scrollbar-thumb {
		background: #999999;
		border-radius: 20px;
	}
	
	.chat-box .list-item {
		display: flex;
		margin: 0 10px 10px 0;
	}
	
	.person1 {
		/* float: left; */
		justify-content: left;
	}
	
	.person1 .chunk-chat-item {
		/* background: #ffffff; */
		/* border-radius: 0 6px 6px 6px; */
	}
	
	.chunk-chat-item {
		padding: 8px;
		border-radius: 0 8px 8px 8px;
		display: inline-block;
		max-width: 80%;
		min-width: 20px;
		min-height: 20px;
		width: auto;
		background: #ecf0f6;
		position: relative;
		text-align: left;
		font-size: 14px;
		color: #333;
		word-wrap: break-word;
		line-height: 20px;
		margin-top: 15px;
		
	}
	
	/* .chunk-chat-item::before {
		width: 0;
		position: absolute;
		top: 0;
		right: -10px;
		border-style: solid;
		border-color: transparent #09aeb0;
		border-width: 0 0 10px 10px;
		content: '';
	} */
	.person2 .chunk-chat-item {
		/* background: #89D961;
		border-radius: 6px 0 6px 6px; */
		padding: 8px;
		border-radius: 8px 0 8px 8px;
		display: inline-block;
		max-width: 80%;
		min-height: 20px;
		width: auto;
		background: #02a49f;
		position: relative;
		text-align: left;
		font-size: 14px;
		color: #fff;
		word-wrap: break-word;
		line-height: 20px;
	}
	
	.person2 {
		justify-content: right;
	}
	
	ul,
	ul li {
		margin: 0;
		padding: 0;
	}
	
	ul li {
		margin: 0;
		list-style-type: none;
	}
	
	.list-footer {
		display: flex;
		align-items: center;
		width: 100%;
		background: #f7f7f7;
		box-sizing: border-box;
		padding: 10px 5px;
	
		span {
			margin-right: 5px;
			width: 30px;
		}
	
		button {
			margin-left: 10px;
		}
	
		.icon {
			width: 26px;
			margin: 0 8px;
			cursor: pointer;
		}
	
		.empty {
			flex: 1;
			border: 1px solid #d9d9d9;
			line-height: 30px;
			text-align: center;
			cursor: pointer;
			border-radius: 4px;
		}

		.submit-btn{
			width: 46px;
			display: flex;
			align-items: center;
			justify-content: center;
			img{
				width: 40px;
			}
			
		}
	
	}
	
	.btn {
		background: #f2f3f5;
	}
	
	.audio-svg:focus {
		color: #02a49f;
	}
	
	span.ant-input-suffix:active {
		color: #02a49f;
	}
	
	.chunk-chat-row-logo-l {
		margin: 5px 10px 0 15px;
	}
	
	.ant-input {
		&:hover {
			border-color: #02a49f !important;
		}
	
		&:focus {
			border-color: #02a49f !important;
		}
	
	}
	
	.ant-btn-default {
		&:hover {
			border-color: #02a49f !important;
			color: #02a49f !important;
		}
	}
	
	.ant-input-affix-wrapper {
	
		&:hover,
		&:focus,
		&.ant-input-affix-wrapper-focused {
			border-color: #02a49f !important;
		}
	}
	
	.loader {
		position: absolute;
		top: 0px;
		bottom: -100px;
		left: 0px;
		right: 0px;
		margin: auto;
		width: 100px;
		height: 50px;
		display: flex;
		justify-content: center;
	}
	
	.loader .span {
		display: block;
		background: #ccc;
		width: 7px;
		height: 10%;
		border-radius: 14px;
		margin-right: 5px;
		float: left;
		margin-top: 25%;
	}
	
	.loader .span:last-child {
		margin-right: 0px;
	}
	
	.loader .span:nth-child(1) {
		animation: load 2.5s 1.4s infinite linear;
	}
	
	.loader .span:nth-child(2) {
		animation: load 2.5s 1.2s infinite linear;
	}
	
	.loader .span:nth-child(3) {
		animation: load 2.5s 1s infinite linear;
	}
	
	.loader .span:nth-child(4) {
		animation: load 2.5s 0.8s infinite linear;
	}
	
	.loader .span:nth-child(5) {
		animation: load 2.5s 0.6s infinite linear;
	}
	
	.loader .span:nth-child(6) {
		animation: load 2.5s 0.4s infinite linear;
	}
	
	.loader .span:nth-child(7) {
		animation: load 2.5s 0.2s infinite linear;
	}
	
	.loader .span:nth-child(8) {
		animation: load 2.5s 0s infinite linear;
	}
	
	.loader .span:nth-child(9) {
		animation: load 2.5s 0.2s infinite linear;
	}
	
	.loader .span:nth-child(10) {
		animation: load 2.5s 0.4s infinite linear;
	}
	
	.loader .span:nth-child(11) {
		animation: load 2.5s 0.6s infinite linear;
	}
	
	.loader .span:nth-child(12) {
		animation: load 2.5s 0.8s infinite linear;
	}
	
	.loader .span:nth-child(13) {
		animation: load 2.5s 1s infinite linear;
	}
	
	.loader .span:nth-child(14) {
		animation: load 2.5s 1.2s infinite linear;
	}
	
	.loader .span:nth-child(15) {
		animation: load 2.5s 1.4s infinite linear;
	}
	
	@keyframes load {
		0% {
			background: #ccc;
			margin-top: 25%;
			height: 10%;
		}
	
		50% {
			background: #444;
			height: 100%;
			margin-top: 0%;
		}
	
		100% {
			background: #ccc;
			height: 10%;
			margin-top: 25%;
		}
	}
	
	.list-item {
		position: relative;
	
		.loading1 {
			position: absolute;
			left: 50px;
			width: 100px;
			height: 30px;
			top: 5px;
			text-align: center;
			font-size: 10px;
			&.left{
				left: 50px;
			}
			&.right{
				right: 50px;
			}
	
			div {
				background-color: #02a49f;
				height: 100%;
				border-radius: 3px;
				width: 6px;
				margin-right: 5px;
				display: inline-block;
				-webkit-animation: stretchdelay 1.2s infinite ease-in-out;
				animation: stretchdelay 1.2s infinite ease-in-out;
			}
	
			.rect2 {
				-webkit-animation-delay: -1.1s;
				animation-delay: -1.1s;
			}
	
			.rect3 {
				-webkit-animation-delay: -1.0s;
				animation-delay: -1.0s;
			}
	
			@-webkit-keyframes stretchdelay {
	
				0%,
				40%,
				100% {
					-webkit-transform: scaleY(0.4)
				}
	
				20% {
					-webkit-transform: scaleY(1.0)
				}
			}
	
			@keyframes stretchdelay {
	
				0%,
				40%,
				100% {
					transform: scaleY(0.4);
					-webkit-transform: scaleY(0.4);
				}
	
				20% {
					transform: scaleY(1.0);
					-webkit-transform: scaleY(1.0);
				}
			}
		}
	
	
	
	}
}

